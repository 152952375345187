import NavigationBar from './components/NavigationBar';
import Intro from './components/Intro';
import UserInformation from './components/UserInformation';
import { MsalProvider } from '@azure/msal-react';
import { msalInstance } from './auth/InitializeMsal';

function App() {
  return (
    <MsalProvider instance={msalInstance}>
      <NavigationBar />
      <div className="container" id="AppContainer">
        <div className="row mt-3 py-5">
          <div className="col">
            <Intro />
            <UserInformation />
          </div>
        </div>
      </div>
    </MsalProvider>
  );
}

export default App;
