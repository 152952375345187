import { useAccount, useMsal } from '@azure/msal-react';
import { useEffect, useState } from 'react';
import { Row, Table, Col, Button } from 'react-bootstrap';
import {
  getFeature1Forecast,
  getFeature2Forecast,
  getPermissionFlags,
} from '../api/weatherForecastAPI';
import { TokenClaims } from '../types/TokenClaims';
import { PermissionFlagModel } from '../types/PermissionFlagsModel';
import { getAccessToken } from '../api/apiUtils';
import axios from 'axios';

export const UserInformation = () => {
  const { accounts } = useMsal();
  const account = useAccount(accounts?.[0] || {});
  const tokenClaims = account?.idTokenClaims as TokenClaims;

  const [permissionFlags, setPermissionFlags] = useState(
    {} as PermissionFlagModel,
  );

  const handleFeature1Click = async () => {
    await getFeature1Forecast().then((response) => {
      console.log('Api Response:', response);
    });
  };

  const handleFeature2Click = async () => {
    await getFeature2Forecast().then((response) => {
      console.log('Api Response:', response);
    });
  };

  useEffect(() => {
    //Ideally this would be done at a higher level and the permissions would be stored in state/Redux
    account &&
      getPermissionFlags().then((response: PermissionFlagModel) => {
        setPermissionFlags(response);
      });
  }, []);

  return (
    <>
      {account && (
        <>
          <Row className="justify-content-md-center">
            <span>
              <h3>User Profile</h3>
            </span>
          </Row>
          <Row>
            <Col md={{ span: 6, offset: 3 }}>
              <>
                <Table striped bordered>
                  <thead>
                    <tr>
                      <th>Claim</th>
                      <th>Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Given Name</td>
                      <td>{tokenClaims.given_name}</td>
                    </tr>
                    <tr>
                      <td>Surname</td>
                      <td>{tokenClaims.family_name}</td>
                    </tr>
                    <tr>
                      <td>Auth User Id</td>
                      <td>{tokenClaims.auth_user_id}</td>
                    </tr>
                  </tbody>
                </Table>
                <Row>
                  <Col className="text-center">
                    <Button
                      disabled={!permissionFlags.canUseFeature1}
                      onClick={handleFeature1Click}
                    >
                      Call Feature One
                    </Button>
                  </Col>
                  <Col className="text-center">
                    <Button
                      disabled={!permissionFlags.canUseFeature2}
                      onClick={handleFeature2Click}
                    >
                      Call Feature Two
                    </Button>
                  </Col>
                </Row>
              </>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default UserInformation;
