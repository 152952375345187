import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

import App from './App';
import { initializeMsalInstance } from './auth/InitializeMsal';

const AppProvider = () => (
  <React.StrictMode>
    <App></App>
  </React.StrictMode>
);

fetch('/config.json')
  .then((response) => {
    return response.json();
  })
  .then((data) => {
    (window as any)['runConfig'] = data;

    initializeMsalInstance();

    ReactDOM.render(<AppProvider />, document.getElementById('root'));
  });
