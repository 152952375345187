import { Row, Col, Card } from 'react-bootstrap';

const Intro = () => {
  return (
    <Row>
      <Col>
        A sample app to demonstrate some of the Azure AD B2C functionalities. It
        uses{' '}
        <a href="https://github.com/AzureAD/microsoft-authentication-library-for-js/tree/2ac9a8a3da75aaf40fa111e97ecff24ebd778958/lib/msal-react">
          MSAL React library
        </a>{' '}
        and provides functionalities to sign-in, sign-up users using OAuth
        Authorization Code Flow with PKCE.
      </Col>
    </Row>
  );
};

export default Intro;
