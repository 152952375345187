import axios from 'axios';
import { getAccessToken } from '../api/apiUtils';

export async function getFeature1Forecast() {
  const baseUrl =
    (window as any)['runConfig']?.REACT_APP_BFF_API_BASEURL ??
    process.env.REACT_APP_BFF_API_BASEURL;

  const fullUrl = baseUrl + 'feature1';
  const token = await getAccessToken();

  console.log('Token from call:', token);
  console.log('Calling web API...');

  return axios
    .get(fullUrl, { headers: { Authorization: 'Bearer ' + token } })
    .then((response) => response.data);
}

export async function getFeature2Forecast() {
  const baseUrl =
    (window as any)['runConfig']?.REACT_APP_BFF_API_BASEURL ??
    process.env.REACT_APP_BFF_API_BASEURL;

  const fullUrl = baseUrl + 'feature2';
  const token = await getAccessToken();

  console.log('Token from call:', token);
  console.log('Calling web API...');

  return axios
    .get(fullUrl, { headers: { Authorization: 'Bearer ' + token } })
    .then((response) => response.data);
}

export async function getPermissionFlags() {
  const baseUrl =
    (window as any)['runConfig']?.REACT_APP_BFF_API_BASEURL ??
    process.env.REACT_APP_BFF_API_BASEURL;

  const fullUrl = baseUrl + 'permissionFlags';
  const token = await getAccessToken();

  return axios
    .get(fullUrl, { headers: { Authorization: 'Bearer ' + token } })
    .then((response) => response.data);
}
