import {
  PublicClientApplication,
  EventType,
  AuthenticationResult,
} from "@azure/msal-browser";
import { getConfig, loginRequest } from "./authConfig";

export var msalInstance: PublicClientApplication = new PublicClientApplication(
  getConfig()
);

export const initializeMsalInstance = () => {
  msalInstance = new PublicClientApplication(getConfig());
  msalInstance.addEventCallback((event) => {
    const authResult = event.payload as AuthenticationResult;
    if (event.eventType === EventType.LOGIN_SUCCESS && authResult.account) {
      const account = authResult.account;
      msalInstance.setActiveAccount(account);
    }

    if (event.eventType === EventType.LOGIN_FAILURE) {
      // Catch the cancelled partially self asserted erorr (partial forgotten password or partial finished sign up )
      // and redirect back to login
      if (event.error?.message.includes("AADB2C90091")) {
        msalInstance.loginRedirect(loginRequest);
      }
    }
  });
  console.log(
    "msal init. Config is typically not loaded first time this happens"
  );
};
