import { Button, Dropdown, DropdownButton } from 'react-bootstrap';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
  useAccount,
} from '@azure/msal-react';
import { AccountInfo, EventType } from '@azure/msal-browser';
import { loginRequest, getScopes, logoutRequest } from '../auth/authConfig';

import GetB2CPolicyInfo from '../auth/b2cPolicies';
import { useEffect } from 'react';

export const SignInSignOutButton = () => {
  const { instance, accounts } = useMsal();
  let account: AccountInfo | null;
  account = useAccount(accounts[0] || {});

  let authority = GetB2CPolicyInfo().authorities.editProfile.authority;
  let scopes = getScopes();

  useEffect(() => {
    const callbackId = instance.addEventCallback((message: any) => {
      if (message.eventType === EventType.LOGIN_FAILURE) {
        // The user has forgotten their password.
      }
      if (message.eventType === EventType.ACQUIRE_TOKEN_FAILURE) {
        console.log(message);
      }
    });
    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, [instance]);

  return (
    <>
      <AuthenticatedTemplate>
        <Dropdown>
          <Dropdown.Toggle variant="outline-secondary" id="dropdown-user">
            {account?.name}
          </Dropdown.Toggle>
          <Dropdown.Menu align={'right'}>
            <Dropdown.Item
              onClick={() => {
                instance.loginRedirect({
                  scopes: scopes,
                  authority: authority,
                });
              }}
            >
              Edit Profile
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => instance.logoutRedirect(logoutRequest)}
            >
              Sign Out
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Dropdown>
          <DropdownButton
            id="dropdown-basic-button"
            title="Sign In"
            variant="secondary"
            className="ml-auto"
          >
            <Dropdown.Item
              onClick={() => {
                instance.loginPopup(loginRequest).catch((error) => {
                  console.log(error);
                });
              }}
            >
              Sign In With Popup
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                instance.loginRedirect(loginRequest).catch((error) => {
                  console.log(error);
                });
              }}
            >
              Sign In With Redirect
            </Dropdown.Item>
          </DropdownButton>
        </Dropdown>
      </UnauthenticatedTemplate>
    </>
  );
};

export default SignInSignOutButton;
