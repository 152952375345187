import { msalInstance } from '../auth/InitializeMsal';

export async function getAccessToken() {
  const activeAccount = msalInstance.getActiveAccount();
  const accounts = msalInstance.getAllAccounts() ?? [];

  if (!activeAccount && accounts.length === 0) {
    throw new Error('No active account');
  }

  const authResult = await msalInstance
    .acquireTokenSilent({
      scopes: [(window as any)['runConfig']?.REACT_APP_BFF_API_SCOPES],
      account: activeAccount ?? accounts[0],
    })
    .catch((error) => {
      // fallback to interaction when silent call fails
      return msalInstance.acquireTokenRedirect({
        scopes: [(window as any)['runConfig']?.REACT_APP_BFF_API_SCOPES],
        account: activeAccount ?? accounts[0],
      });
    });

  return authResult?.accessToken;
}

const apiUtils = { getAccessToken };
export default apiUtils;
